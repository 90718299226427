.swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background: #fff;
  opacity: 1; /* Ensure full visibility */
}

.swiper-pagination-bullet-active {
  background: #e1251b;
}
